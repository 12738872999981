.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  background-color: #8ac926;
  height: 55rem;
  width: auto;
}

.iconStyles{
  color: rgb(249 254 122);
  font-size: 3em !important;
}